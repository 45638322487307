.results {
    align-items: center;
    justify-content: center;
    margin: 10vh 8vw;
    text-align: center;
    color: #fff;
    font-size: larger;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.images-c1 {
    display: flex;
    flex-direction: column;
}

.images-c3 {
    display: flex;
    flex-direction: column;
}

.r-images {
    display: flex;
}

.img1 {
}

.img2 {
    /* scale: 0.9; */
    /* border-radius: px; */
}


.img1 img {
    scale: 0.9;
    width: 100%;
    border-radius: 25px;
}
.img2 img {
    scale: 0.9;
    width: 100%;
    border-radius: 25px;
}
.img3 img {
    scale: 0.9;
    width: 100%;
    border-radius: 25px;
}
.img4 img {
    scale: 0.9;
    width: 100%;
    border-radius: 25px;
}
.img5 img {
    scale: 0.9;
    width: 100%;
    border-radius: 25px;
}

.img3 {
    margin: 5px;

}

.img4 {
    margin: 5px;

}

.img5 {
    margin: 5px;

}
@media only screen and (max-width: 600px){

    .images-c1 {
        display: flex;
        flex-direction: column;
    }
    
    .images-c3 {
        display: flex;
        flex-direction: column;
    }
    
    .r-images {
        display: flex;
    }
    
    .img1 {
    }
    
    .img2 {
        /* scale: 0.9; */
        /* border-radius: px; */
    }
    
    
    .img1 img {
        scale: 1;
        width: 100%;
        display: none;
        border-radius: 15px;
    }
    .img2 img {
        scale: 1;
        width: 100%;
        border-radius: 15px;
    }
    .img3 img {
        scale: 1;
        width: 100%;
        border-radius: 15px;
    }
    .img4 img {
        scale: 1;
        width: 100%;
        border-radius: 15px;

    }
}
    .img5 img {
        scale: 1;
        width: 100%;
        border-radius: 15px;
    }
    
    .img3 {
        margin: 5px;
    
    }
    
    .img4 {
        margin: 5px;
    
    }
    
    .img5 {
        margin: 5px;
    
    


}