.navbar {
    display: flex;
    color: #FFFFFF;
    margin: 0;
    padding: 0 4vw 0vh;
    font-family: Arial, Helvetica, sans-serif;

}

.n-left {
    flex: 1;
}

.navbar a {
    text-decoration: none;
    color: #FFFFFF;

}
a{
    text-decoration: none;
    color: #000;
}

.n-right {
    font-size: 20px;
    font-weight: 600;
    margin: auto;
}

ul {
    display: flex;
}

li {
    display: flex;
    margin-right: 2.962vw;
    font-size: 3.5vh;
}

.img {}
@media only screen and (max-width: 600px) {
    .n-right {
        font-size: 12px;
        font-weight: 600;
        margin: auto;
    }
    li {
        display: flex;
        margin-right: 3vw;
        font-size: 2vh;
    }
    .navbar img{
        width: 15vh;
    }
}
