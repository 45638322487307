.introduction {
    color: #FFFFFF;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;

}

.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
}


@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(40px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .heading {
    font-size: 8vh;
    font-weight: 800;
    text-align: center;
    width: 58vw;
    margin-bottom: 1vh;
    animation: fadeInUp 1s ease-out;
  }
  
.content {
    font-weight: 200;
    text-align: center;
    width: 40%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 20px;
    transition: all 0.3s ease;
}

.content:hover {
    transform: scale(1.05);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}


@media only screen and (max-width: 600px) {
  .introduction {
   
    margin-top: 0.5vh;

}

.heading {
  font-size: 4vh;
  font-weight: 800;
  width: 80vw;

  margin: 5vh 0 0.2vh;
}

.content {
 width: 55%;
 
}
}

