body {
    margin: 0;
    padding: 0;
}

.footer {
    display: flex;
    align-items: center;
    padding: 0 4vw 0.5vh;
    background-color: #221F2D;

}

.logo {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0;

}

.logo img {
    width: 19vw;

}

.logo h2 {
    color: white;

    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.credentials {
    flex: 1;
    color: #fff;
    text-align: center;
    margin: 0;
}

.credentials h3 {
    font-size: 2.5vh;
    margin: 5px;

}

.contact-email {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #fff;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 200;
}

.contact-email h3 {
    margin: 0;
    font-size: 3vh;

}
.contact-email h4 {
    margin: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2vh;
}


@media only screen and (max-width: 768px) {

.credentials h3 {
    font-size: 8px;
    /* margin: 5px;pa */
    /* padding: 2px; */

}



.contact-email h3 {
    font-size: 10px;

}
.contact-email h4 {
    
    font-size: 9px;
}
}