.contact {
  display: flex;
  margin-left: 8vw;
  color: #FFF;
  margin-top: 10vh;
  margin-bottom: 10vh;
  align-items: center;
  margin-right: 8vw;

}

.c-left h1 {
  font-size: 8vh;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-decoration: underline 0.3vh;
}

.c-left h3 {
  font-size: 3.4vh;
  font-weight: 400;
  text-align: left;
  width: 85%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.c-left {
  width: 40%;
  padding: 2vw;
  flex: 1;

}


.c-right {
  width: 60%;
  font-size: 2.5vh;
  flex: 1;
  margin-left: 4vw;
 
  border-radius: 10px; 
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); 
}



form{
  background-color: #2c284d5d; 
   padding: 1vw 0vw 2.5vw 2vw;
   border-radius: 25px;
   justify-content: center;
}


.fname {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.name {
  display: flex;


}

.fmail {
  display: flex;
  flex-direction: column;



}

.name input {
  width: 18vw;


}


.fmail input {
  width: 39vw;
}

.fmail textarea {

  border-radius: 6px;
  width: 39vw;
  height: 25vh;

}


label {
  font-family: 'Times New Roman', Times, serif;
  font-weight: 400;
  margin: 2vh 0 0.5vh;
}

input {
  margin: 1vh 2vw 1vh 0;
  border-radius: 6px;
  height: 7vh;
}

.f-button{
  width: 130px;
  height: 6vh;
  border-radius: 30px;
  background: #32D4D7;
  font-size: 2vh;
  margin: auto;
  margin-top: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 550;
  
}
button:hover {
  background-color: #2aafb1;
  color: #FFF;
}

@media only screen and (max-width: 600px) {

  .contact {
    
    flex-direction: column;
    
    margin-top: 0.5vh;
  
  }
  
  .c-left h1 {
    font-size: 4vh;
    text-decoration: underline 0.1vh;
  }
  
  .c-left h3 {
    font-size: 2.2vh;
    font-weight: 400;
    text-align: left;
    width: 100%;
    
  }
  
  .c-left {
    width: 100%;
    
  
  }
  
  
  .c-right {
    width: 70%;
    font-size: 2vh;
    margin-left: 0vw;
   
    border-radius: 5px; 
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); 
  }
  
  form{
    background-color: #2c284d5d; 
     padding:0 0 3vh 4vw;
     border-radius: 10px;
  }
  
  
  .fname {
    display: flex;
    flex-direction: column;
  }
  
  .name {
    display: flex;
    
  
  
  }
  
  .fmail {
    display: flex;
    flex-direction: column;
  
  
  
  }
  
  .name input {
    width: 20vw;
    height: 1.9vh;
    border-radius: 5px;

  
  
  }
  
  
  .fmail input {
    width: 47.7vw;
    height: 2vh;
    border-radius: 5px;

  }
  
  .fmail textarea {
  
    border-radius: 6px;
    width: 47.7vw;
    height: 8vh;
  
  }
  
  
  label {
    font-family: 'Times New Roman', Times, serif;
    font-weight: 400;
    margin: 2vh 0 0.5vh;
  }
  
  input {
    margin: 0vh 0vw 0vh 0;
    border-radius: 5px;
  }

  .f-button{
    width: 130px;
    height: 3.8vh;
    border-radius: 30px;
    background: #32D4D7;
    font-size: 1.7vh;
    margin-top: 2.5vh;
    
    
  }

}
